(function () {
    var context = pwNamespace('PEAKWORK.TOOLKIT');
    
    /**
     * This static class allows to enable xeditable for all defined fields inside
     * a container.
     */
    context.XEditable = (function () {
        
        /**
         * Adds editable fields.
         * @param {Element} container
         */
        var initEditableFields = function (container) {
            var elements = container.find('[data-modus="editable"]'), i,
                elementsLength = elements.length, el, uuid = $.uuid();
            
            for (i = 0; i < elementsLength; i++) {
                el = $(elements[i]);
                
                /* prepare elements to be openable automatically */
                el.attr('data-uuid', uuid);
                
                /* add input field next to item */
                el.on('init', function(e, editable) {
                    var item = $(this);
                    
                    createHiddenFieldByElement(item);
                    if ('true' === item.attr('data-valueoninit')) {
                        editable.setValue(editable.value);
                    }
                });

                /* init editable */
                el.editable({
                    mode: 'inline'
                    ,pk: i
                    ,showbuttons: false
                    ,onblur: 'submit'
                    ,validate: function(value) {
                        validateField($(this), value);
                    }
                });

                /* change input field item */
                el.on('save', function(event, params) {
                    var item = $(this);
                    
                    event.preventDefault();
                    saveHiddenFieldValue(item, params);
                    afterChangeFunction(item, params);
                    
                });
                
                /* validates field on blur */
                el.on('hidden', function(event, reason) {
                    var element = $(this);
                    element.editable('validate');
                });
                
                /* Register mousedown to stop showing editable on focus  */
                el.on('mousedown', function() {
                    var element = $(this);
                    element.attr('data-xeditablemousedown', true);
                });
                
                /* show field on focus */
                el.on('focus', function(event) {
                    var element = $(this);
                    if ('true' !== element.attr('data-xeditablemousedown')) {
                        element.editable('show', true);
                    }
                    
                    element.removeAttr('data-xeditablemousedown');
                });
                
                /* activate input after shown - use timeout as a hack to get it work */
                el.on('shown', function(event, editable) {
                    if (
                        'text' === editable.input.type ||
                        'textarea' === editable.input.type ||
                        'typeahead' === editable.input.type
                    ) {
                        setTimeout(function() {
                            editable.input.activate();
                        }, 50);
                    }
                });
            }
        };
        
        /**
         * Validates the input.
         * @param {Element} el
         * @param {Mixed} value
         */
        var validateField = function(el, value) {
            var item = el.get(0),
                element = 'undefined' !== typeof item.$element ?
                    item.$element : $(item);
            
            removeError(element);
            if ('true' === element.attr('data-required') && $.trim(value) === '') {
                addError(element, Translator.trans('backend.editable.fieldisrequired', {}, 'peakwork'));
            } else {
                executeCustomValidateFunction(element, value);
            }
        };
        
        /**
         * Executes the custom validation for a field.
         * @param {element} element
         * @param {Mixed} value
         */
        var executeCustomValidateFunction = function(element, value) {
            var onvalidate = element.attr('data-onvalidate')
                ,fn
                ,validateMsg;

            if ('undefined' !== typeof onvalidate) {
                fn = getFunctionFromString(onvalidate);
                if ('function' === typeof fn) {
                    validateMsg = fn.call(this, value, element);
                    if ('undefined' !== typeof validateMsg && '' !== validateMsg) {
                        addError(element, validateMsg);
                    }
                }
            }
        };
        
        /**
         * Checks for custom function after saved the new value
         * @param {Element} el
         * @param {Object} params
         */
        var afterChangeFunction = function(el, params) {
            var item = el.get(0)
                ,element = 'undefined' !== typeof item.$element ?
                    item.$element : $(item)
                ,onsave = element.attr('data-onsave')
                ,fn;

            if ('undefined' !== typeof onsave) {
                fn = getFunctionFromString(onsave);
                if ('function' === typeof fn) {
                    fn.call(this, params, el, element);
                }
            }
        };
        
        /**
         * Registers a click event to readonly links to stop event default.
         * @param {Element} container
         */
        var initReadonlyFields = function(container) {
            container.on('click', '[data-modus="readonly"]', function(event) {
                event.preventDefault();
            });
        };
        
        /**
         * Tries to find a function for the given string.
         * @param {String} functionName
         * @returns function|undefined
         */
        var getFunctionFromString = function(functionName) {
            var scope = window
                ,i = 0
                ,scopeSplit = functionName.split('.')
                ,scopeLength = scopeSplit.length;
            
            for (i = 0; i < scopeLength - 1; i++) {
                scope = scope[scopeSplit[i]];
                if (scope === undefined) {
                    return;
                }
            }

            return scope[scopeSplit[scopeSplit.length - 1]];
        };
        
        /**
         * Set the new input value.
         * @param {Element} container
         */
        var initHiddenValuesFields = function(container) {
            container.on('click', '[data-modus="hiddenvalues"]', function(event) {
                event.preventDefault();
            });

            container.find('[data-modus="hiddenvalues"]').each(function(index, item) {
                createHiddenFieldByElement($(item));
            });
        };
        
        /**
         * Writes the input value into the hidden field.
         * @param {Element} el
         * @param {Object} params
         */
        var saveHiddenFieldValue = function(el, params) {
            switch (el.data('type')) {
                case 'textarea': {
                    $('textarea[name="' + el.data('name') + '"]').val(params.newValue);
                    break;
                }
                case 'checklist': {
                    $('input[name="' + el.data('name') + '"]').val(params.newValue.join());
                    break;
                }
                default: {
                    $('input[name="' + el.data('name') + '"]').val(params.newValue);
                }
            }

            el.trigger('pw-editable-saved', [el, params]);
        };
        
        /**
         * Creates the correct hidden value field depending on the element data.
         * @param {Element} el
         */
        var createHiddenFieldByElement = function(el) {
            switch (el.data('type')) {
                case 'filelist': {
                    el.after(
                        '<textarea class="hidden" ' +
                        'name="' + el.data('name') + '">' +
                        el.attr('data-value') +
                        '</textarea>'
                    );
                    break;
                }
                case 'textarea': {
                    el.after(
                        '<textarea class="hidden" ' +
                        'name="' + el.data('name') + '">' +
                        el.data('value') +
                        '</textarea>'
                    );
                    break;
                }
                case 'checklist': {
                    el.after(
                        '<input class="hidden" ' +
                        'type="text" name="' + el.data('name') + '" ' +
                        'value="' + el.data('value').replace('[\'', '').replace('\']', '') + '" />'
                    );
                    break;
                }
                default: {
                    el.after(
                        '<input class="hidden" ' +
                        'type="text" name="' + el.data('name') + '" ' +
                        'value="' + el.data('value') + '" />'
                    );
                }
            }
        };
        
        /**
         * Checks, if html form items are empty.
         * @param {Object} element
         * @returns {Boolean}
         */
        var isInputFieldNotEmpty = function(element) {
            if (element.is(':checkbox')) {
                return element.is(':checked');
            }

            if (element.is('textarea')) {
                return '' !== element.val().trim();
            }

            if (element.is('input')) {
                return '' !== element.val().trim();
            }
        };
        
        /**
         * Adds an error message toolkit to the element. Destroys an existing tooltip
         * definition.
         * @param {type} element
         * @param {type} msg
         */
        var addError = function(element, msg) {
            removeError(element);
            element.addClass('text-danger');
            element.tooltip({
                title: msg
                ,placement: 'right'
            });
        };
        
        /**
         * Removes the toolkit and error css class.
         * @param {type} element
         */
        var removeError = function(element) {
            element.tooltip('destroy');
            element.removeClass('text-danger');
        };
        
        
        
        return {
            /**
             * Initializes the editable fields
             * @param {Element} container
             */
            initEditable: function (container) {
                initEditableFields(container);
                initReadonlyFields(container);
                initHiddenValuesFields(container);
            }
            
            /**
             * Checks for a valid form
             * @param {Element} form
             * @param {Boolean} success
             * @param [] errorLabels
             * @param {Function} showErrors
             * @returns {Boolean}
             */
            ,isFormValid: function(form, success, errorLabels, showErrors) {
                var editFields = form.find('[data-modus="editable"]'), i = 0,
                    editFieldsLength = editFields.length,
                    element, 
                    requiredFields = form.find('input[data-required="true"]'),
                    requiredFieldsLength = requiredFields.length;

                success = 'undefined' === typeof success ? true : success;
                errorLabels = 'undefined' === typeof errorLabels ? [] : errorLabels;

                /* check all editable fields */
                if (editFieldsLength > 0) {
                    for (i = 0; i < editFieldsLength; i++) {
                        element = $(editFields[i]);
                        element.removeClass('text-danger');
                        if (element.hasClass('text-danger')) {
                            success = false;
                            errorLabels.push(
                                'undefined' !== typeof element.data('label') ?
                                element.data('label') :
                                element.closest('.form-group').find('label').text().trim()
                            );
                        }
                    }
                }

                /* check all required fields */
                if (requiredFieldsLength > 0) {
                    for (i = 0; i < requiredFieldsLength; i++) {
                        element = $(requiredFields[i]);

                        if (false === isInputFieldNotEmpty(element)) {
                            success = false;
                            element.addClass('text-danger');
                            errorLabels.push(element.data('label'));
                        } else {
                            element.removeClass('text-danger');
                        }
                    }
                }

                if ('function' === typeof showErrors && false === success && errorLabels.length > 0) {
                    showErrors.call(this, errorLabels);
                }

                return success;
            },
            
            /**
             * Adds an error message to the element.
             * @param {Element} element
             * @param {String} msg
             */
            error: function(element, msg) {
                addError(element, msg);
            }
        };
    })();
})();
